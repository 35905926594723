<template>
  <div class="row mb-4">
    <div class="col-12 btn-right">
      <button class="btn-outlined" @click="getConfig">My configurations</button>
    </div>
  </div>
  <!-- First modal -->
  <el-dialog v-model="showAllConfig" title="My Configurations">
    <div
      class="card modal-card all-config"
      v-for="item in savedConfig"
      :key="item.conf_label"
    >
      <div class="row">
        <div class="col-12 col-md-7 text-col">
          <h3>{{ item.conf_label }}</h3>
        </div>
        <div class="col-12 col-md-5 btn-right">
          <el-button type="text" @click="selectConfig(item.conf_label)"
            >Select</el-button
          >
        </div>
      </div>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="showAllConfig = false">
          Close
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
export default {
  name: 'SavedConfig',
  emits: ['confSelected'],
  data() {
    return {
      showAllConfig: false,
      savedConfig: [],
      selectedConfig: {
        conf_label: '',
        conf: {},
      },
    };
  },
  methods: {
    async getConfig() {
      const savedConfig = await this.$store.dispatch('getSavedConfig');
      this.savedConfig = savedConfig;
      this.showAllConfig = true;
    },
    async selectConfig(label) {
      this.getSingleConfig(label);
      const serverData = this.selectedConfig.conf;
      // const results = await this.$store.dispatch('getCompareData', serverData);
      // console.log(results)
      // give the option to change the amount of servers

      const formatted = this.formatConfig(serverData);

      this.$emit('confSelected', {
        ...formatted,
        quantity: 1,
        results: {},
      });

      this.showAllConfig = false;
    },
    getSingleConfig(label) {
      const selected = this.savedConfig.find(
        (item) => item.conf_label === label
      );
      this.selectedConfig = selected;
    },
    formatConfig(serverData) {
      let config = {};
      let server = {};

      Object.keys(serverData).forEach((key) => {
        if (key === 'server') server = serverData[key];
        else config[key] = serverData[key];
      });

      return { config, server };
    },
  },
};
</script>

<style lang="scss">
.all-config,
.single-config {
  height: fit-content;
}

.all-config {
  h3 {
    font-size: 20px;
    margin-bottom: 0;
  }
  .text-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
</style>
