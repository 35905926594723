const months = [
  { label: '1 month', value: 1 },
  { label: '2 months', value: 2 },
  { label: '3 months', value: 3 },
  { label: '4 months', value: 4 },
  { label: '5 months', value: 5 },
  { label: '6 months', value: 6 },
  { label: '7 months', value: 7 },
  { label: '8 months', value: 8 },
  { label: '9 months', value: 9 },
  { label: '10 months', value: 10 },
  { label: '11 months', value: 11 },
  { label: '12 months', value: 12 },
  { label: '13 months', value: 13 },
  { label: '14 months', value: 14 },
  { label: '15 months', value: 15 },
  { label: '16 months', value: 16 },
  { label: '17 months', value: 17 },
  { label: '18 months', value: 18 },
  { label: '19 months', value: 19 },
  { label: '20 months', value: 20 },
  { label: '21 months', value: 21 },
  { label: '22 months', value: 22 },
  { label: '23 months', value: 23 },
  { label: '24 months', value: 24 },
  { label: '25 months', value: 25 },
  { label: '26 months', value: 26 },
  { label: '27 months', value: 27 },
  { label: '28 months', value: 28 },
  { label: '29 months', value: 29 },
  { label: '30 months', value: 30 },
  { label: '31 months', value: 31 },
  { label: '32 months', value: 32 },
  { label: '33 months', value: 33 },
  { label: '34 months', value: 34 },
  { label: '35 months', value: 35 },
  { label: '36 months', value: 36 },
  { label: '37 months', value: 37 },
  { label: '38 months', value: 38 },
  { label: '39 months', value: 39 },
  { label: '40 months', value: 40 },
  { label: '41 months', value: 41 },
  { label: '42 months', value: 42 },
  { label: '43 months', value: 43 },
  { label: '44 months', value: 44 },
  { label: '45 months', value: 45 },
  { label: '46 months', value: 46 },
  { label: '47 months', value: 47 },
  { label: '48 months', value: 48 },
];

export default months;
