const countries = [
  {
    name: 'Other',
    code: 'OT'
  },
  {
    name: 'Austria',
    code: 'AT'
  },
  {
    name: 'Belgium',
    code: 'BE'
  },
  {
    name: 'Switzerland',
    code: 'CH'
  },
  {
    name: 'Czech Republic',
    code: 'CZ'
  },
  {
    name: 'Germany',
    code: 'DE'
  },
  { 
    name: 'Denmark',
    code: 'DK'
  },
  { 
    name: 'Spain',
    code: 'ES'
  },
  { 
    name: 'France',
    code: 'FR'
  },
  {
    name: 'Ireland',
    code: 'IE'
  },
  { 
    name: 'Italy',
    code: 'IT'
  },
  {
    name: 'Luxembourg',
    code: 'LU'
  },
  { 
    name: 'Netherlands',
    code: 'NL'
  },
  { 
    name: 'Poland',
    code: 'PL'
  },
  { 
    name: 'Sweeden',
    code: 'SE'
  },
  { 
    name: 'United Kingdom',
    code: 'UK'
  }
]

export default countries;