<template>
  <div class="card modal-card">
    <div class="row config-item">
      <div class="col-12 col-md-3"><h4>Target Load:</h4></div>
      <div class="col-12 col-md-9">
        <p class="break-word">
          Please specify your typical target load in a single 24 hour period.
          The tool assumes a default value of 25% target load (average for a
          service provider) if unknown. Also the tool assumes a lifetime of 4
          years.
        </p>
        <p class="text-bold mb-4 text-red" v-if="showPowerError">
          Total hours must be 24.
        </p>
        <PowerItem
          v-for="(item, index) in powerItems"
          :key="index"
          @remove="removePower"
          :index="index"
          :item="item"
          @dataChange="handlePower"
        />
      </div>
    </div>
    <div class="btn-center">
      <el-button type="primary" @click="addPower"
        ><i class="bi bi-plus-lg"></i>Add</el-button
      >
    </div>
  </div>
  <div class="card modal-card">
    <div class="row config-item">
      <div class="col-12 col-md-3"><h4>Country:</h4></div>
      <div class="col-12 col-md-9">
        <p class="text-bold mb-4 text-red" v-if="showCountryError">
          Total time of operation must be 48months.
        </p>
        <CountryItem
          v-for="(item, index) in countryItems"
          :key="index"
          :item="item"
          :index="index"
          @remove="removeCountry"
          @dataChange="handleCountry"
        />
      </div>
    </div>
    <div class="btn-center">
      <el-button type="primary" @click="addCountry"
        ><i class="bi bi-plus-lg"></i>Add</el-button
      >
    </div>
  </div>
  <div class="btn-right mt-4">
    <el-button type="default" @click="goBack">Back</el-button>
    <el-button
      type="primary"
      :disabled="showPowerError || showCountryError"
      @click="saveEnergy"
      >Save</el-button
    >
  </div>
</template>

<script>
import CountryItem from '@/components/configure/items/CountryItem';
import PowerItem from '@/components/configure/items/PowerItem';

export default {
  name: 'EnergyConfig',
  emits: ['saveEnergy', 'goBack'],
  props: ['config'],
  components: {
    CountryItem,
    PowerItem,
  },
  data() {
    return {
      showCountryError: false,
      showPowerError: false,
      powerItems: [
        {
          percentage: '0.25',
          hours: 24,
        },
      ],
      countryItems: [
        {
          country: 'OT',
          percentage: 48,
        },
      ],
    };
  },
  created() {
    if (Object.keys(this.config.location).length !== 0)
      this.getCountryConfig(this.config.location);
    if (Object.keys(this.config.target_load).length !== 0)
      this.getPowerConfig(this.config.target_load);
  },
  methods: {
    getCountryConfig(config) {
      let location = [];
      Object.keys(config).forEach((key) => {
        location.push({
          country: key,
          percentage: config[key],
        });
      });

      this.countryItems = location;
    },
    getPowerConfig(config) {
      let power = [];
      Object.keys(config).forEach((key) => {
        power.push({
          percentage: key,
          hours: config[key],
        });
      });
      this.powerItems = power;
    },
    goBack() {
      this.$emit('goBack');
    },
    saveEnergy() {
      this.validateCountry();
      this.validatePower();

      let target_load = {};
      let location = {};

      for (let i in this.powerItems) {
        target_load[this.powerItems[i].percentage] = this.powerItems[i].hours;
      }

      for (let i in this.countryItems) {
        location[this.countryItems[i].country] =
          this.countryItems[i].percentage;
      }

      if (this.showCountryError || this.showPowerError) {
        return;
      }

      this.$emit('saveEnergy', target_load, location);
    },
    addCountry() {
      this.countryItems.push({
        country: 'OT',
        percentage: 1,
      });
    },
    addPower() {
      this.powerItems.push({
        percentage: '0.25',
        hours: 1,
      });
    },
    removeCountry(index) {
      this.countryItems.splice(index, 1);
      this.showCountryError = false;
    },
    removePower(index) {
      this.powerItems.splice(index, 1);
      this.showPowerError = false;
    },
    handleCountry(index, country) {
      this.countryItems[index] = country;
      this.validateCountry();
    },
    handlePower(index, target_load) {
      this.powerItems[index] = target_load;
      this.validatePower();
    },
    validateCountry() {
      this.showCountryError = false;
      let total = 0;
      for (let i in this.countryItems) {
        total += this.countryItems[i].percentage;
      }
      if (total !== 48) {
        this.showCountryError = true;
      }
    },
    validatePower() {
      this.showPowerError = false;
      let hours = 0;
      for (let i in this.powerItems) {
        hours += this.powerItems[i].hours;
      }

      if (hours !== 24) {
        this.showPowerError = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-card {
  height: fit-content;
  .el-form {
    display: flex;
    flex-direction: row;
    .el-select {
      min-width: 100%;
    }
  }
  .col-md-3 {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .bi-plus-lg {
    margin-right: 0.5rem;
  }
}

.break-word {
  word-break: normal !important;
}
</style>
