<template>
  <el-form :inline="true" label-position="top">
    <el-form-item label="Target Load (%)">
      <el-select v-model="target_load.percentage" @change="handleChange"
        ><el-option
          v-for="(option, index) in load"
          :key="index"
          :label="option.label"
          :value="option.value"
          >{{ option.label }}</el-option
        ></el-select
      ></el-form-item
    >
    <el-form-item label="Hours">
      <el-select v-model="target_load.hours" @change="handleChange"
        ><el-option
          v-for="(option, index) in hours"
          :key="index"
          :label="option.label"
          :value="option.value"
          >{{ option.label }}</el-option
        ></el-select
      ></el-form-item
    >
    <el-form-item>
      <el-form-item>
        <div class="remove-icon" @click="remove" v-if="index">
          <i class="bi bi-x"></i>
        </div>
      </el-form-item>
    </el-form-item>
  </el-form>
</template>

<script>
import load from '@/utils/data/load';
import hours from '@/utils/data/hours';

export default {
  name: 'PowerItem',
  props: ['item', 'index'],
  emits: ['remove', 'dataChange'],
  data() {
    return {
      target_load: {
        percentage: '0.25',
        hours: 1,
      },
      load,
  hours,
    };
  },
  created() {
    this.target_load = { ...this.item };
  },
  methods: {
    remove() {
      this.$emit('remove', this.index);
    },
    handleChange() {
      this.$emit('dataChange', this.index, this.target_load);
    },
  },
};
</script>

<style lang="scss" scoped>
.el-form {
  .el-select {
    min-width: 100%;
  }
}
.remove-icon {
  cursor: pointer;
  margin-top: 2rem;
  .bi-x {
    font-size: 20px;
    margin-left: 1rem;
  }
}
</style>
