<template>
  <div class="container">
    <div class="row">
      <div class="col-12 header-col mb-2">
        <h1 class="title-w1">Compare</h1>
        <app-info title="Compare">
          <p>
            The CDCC Compare tool uses
            <span class="text-bold">Life Cycle Assessment</span> (LCA) to assess
            the entire life cycle of the equipment including the extraction of
            raw materials, manufacturing/assembly, transportation, use and
            end-of-life stages. The
            <span class="text-bold">Circular Footprint Formula</span> is used to
            account for benefits and burdens, resulting from the use of
            secondary and virgin materials, and recycling and energy recovery.
          </p>
          <p>
            To find out which equipment is most circular,
            <span class="text-bold">select and configure</span> two servers,
            compare the specifications and impacts and
            <span class="text-bold">download</span> the full PDF report.
          </p>
        </app-info>
      </div>
    </div>
    <compare-tool />
  </div>
</template>

<script>
import CompareTool from '@/components/compare/CompareTool';
export default {
  name: 'Compare',
  components: {
    CompareTool,
  },
};
</script>

<style lang="scss" scoped>
.card {
  height: 10rem;
}
</style>
