const load = [
  { label: 'Idle', value: 'idle' },
  { label: '10%', value: '0.1' },
  { label: '20%', value: '0.2' },
  { label: '25%', value: '0.25' },
  { label: '30%', value: '0.3' },
  { label: '40%', value: '0.4' },
  { label: '50%', value: '0.5' },
  { label: '60%', value: '0.6' },
  { label: '70%', value: '0.7' },
  { label: '80%', value: '0.8' },
  { label: '90%', value: '0.9' },
  { label: '100%', value: '1.0' },
];

export default load;
