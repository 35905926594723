<template>
  <div class="card server-card">
    <div class="card-body">
      <h3 class="card-title">{{ title }}</h3>
      <button class="btn btn-outlined" @click="rackModal = true">
        Build your data center
      </button>
    </div>
  </div>
  <el-dialog v-model="rackModal" destroy-on-close>
    <template #title>
      <h4>{{ title }}</h4>
    </template>
    <template #default>
      <el-scrollbar max-height="400px" v-if="rack.length">
        <p class="text-bold mb-4" :class="{ 'text-red': showError }">
          Each server must be configured.
        </p>
        <server-item
          v-for="(item, index) in rack"
          :key="index"
          :index="index"
          :item="item"
          @saveServer="saveServer"
          @removeItem="removeItem"
          @quantity="handleQuantity"
          @isConfigured="isConfigured = true"
        />
      </el-scrollbar>
      <div class="btn-center mt-4">
        <el-button type="primary" @click="addItem"
          ><i class="bi bi-plus-lg"></i>Add</el-button
        >
      </div>
    </template>
    <template #footer>
      <el-button type="primary" @click="saveRack">Save</el-button>
    </template>
  </el-dialog>
</template>

<script>
// Need at least cpu, ram, storage

import ServerItem from '@/components/configure/Server';

export default {
  name: 'RackItem',
  props: ['title'],
  emits: ['saveRack'],
  components: {
    ServerItem,
  },
  data() {
    return {
      rackModal: false,
      showError: false,
      isConfigured: false,
      rack: [
        {
          server: {},
          quantity: 1,
          config: {},
          results: {},
        },
      ],
    };
  },
  methods: {
    addItem() {
      this.rack.push({
        server: {},
        quantity: 1,
        config: {},
        results: {},
      });
    },
    removeItem(index) {
      if (!index) {
        this.rack = [];
        this.$nextTick(() => {
          this.rack = [
            {
              server: {},
              quantity: 1,
              config: {},
              results: {},
            },
          ];
        });
      } else this.rack.splice(index, 1);
    },
    handleQuantity(quantity, index) {
      this.rack[index].quantity = quantity;
    },
    saveServer(data, index) {
      this.rack[index] = data;
      const isValid = this.validateServer();
      if (!isValid) this.showError = true;
      else this.showError = false;
    },
    saveRack() {
      const isValid = this.validateServer();
      if (!isValid) {
        this.showError = true;
      } else {
        this.showError = false;
        this.$emit('saveRack', this.rack);
        this.rackModal = false;
      }
    },
    validateServer() {
      // let isValid = true;
      // for (const server of this.rack) {
      //   const keys = Object.keys(server.config);
      //   if (
      //     !keys.includes('cpu') ||
      //     !keys.includes('ram') ||
      //     !keys.includes('storage')
      //   ) {
      //     isValid = false;
      //   }
      // }
      // return isValid;
      return this.isConfigured ? true : false;
    },
  },
};
</script>

<style lang="scss" scoped>
.server-card {
  display: flex;
  justify-content: space-between;
  height: fit-content;
  .card-title {
    font-size: 1.5rem;
    margin-right: 2rem;
    margin-bottom: 0;
  }
  .card-body {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.bi-plus-lg {
  margin-right: 0.5rem;
}
</style>
