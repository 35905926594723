<template>
  <el-form :inline="true" label-position="top">
    <el-form-item label="Country"
      ><el-select
        v-model="location.country"
        @change="handleChange"
        filterable
        placeholder="Please select a country"
      >
        <el-option
          v-for="country in orderedCountries"
          :key="country.code"
          :value="country.code"
          :label="country.name"
        >{{ country.name}}</el-option> </el-select
    ></el-form-item>
    <el-form-item label="Time of Operation in this country">
      <el-select v-model="location.percentage" @change="handleChange"
        ><el-option
          v-for="item in months"
          :key="item.value"
          :label="item.label"
          :value="item.value"
          >{{ item.label }}</el-option
        ></el-select
      ></el-form-item
    >
    <el-form-item>
      <div class="remove-icon" @click="remove" v-if="index">
        <i class="bi bi-x"></i>
      </div>
    </el-form-item>
  </el-form>
</template>

<script>
import countries from '@/utils/data/countries';
import months from '@/utils/data/months';
import _ from 'lodash';

export default {
  name: 'CountryItem',
  props: ['item', 'index'],
  emits: ['remove', 'dataChange'],
  data() {
    return {
      location: {
        country: 'OT',
        percentage: 1,
      },
      countries,
      months
    };
  },
  computed: {
    orderedCountries() {
      return _.orderBy(this.countries, ['name']);
    }
  },
  created() {
    this.location = { ...this.item };
  },
  methods: {
    remove() {
      this.$emit('remove', this.index);
    },
    handleChange() {
      this.$emit('dataChange', this.index, this.location);
    },
  },
};
</script>

<style lang="scss" scoped>
.el-form {
  .el-select {
    min-width: 100%;
  }
}
.remove-icon {
  cursor: pointer;
  margin-top: 2rem;
  .bi-x {
    font-size: 20px;
    margin-left: 1rem;
  }
}
</style>
