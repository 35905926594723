<template>
  <div class="row compare-chart" id="compare-chart">
    <div class="col-6"><h2 class="title-w3">Comparison Results:</h2></div>
    <div class="col-6">
      <div class="legend">
        <div class="legend__label">
          <div class="legend__label-icon legend__label-icon--primary" />
          <span>{{ chartData[0].name }}</span>
        </div>
        <div class="legend__label">
          <div class="legend__label-icon legend__label-icon--secondary" />
          <span>{{ chartData[1].name }}</span>
        </div>
      </div>
    </div>
    <div class="col-12">
      <apexchart
        type="bar"
        :height="chartHeight"
        :width="chartWidth"
        :options="chartOptions"
        :series="chartData"
      ></apexchart>
    </div>
  </div>
</template>

<script>
import getChartSize from '@/utils/helpers/getChartSize';

export default {
  name: 'CompareChart',
  props: ['chartData'],
  data() {
    return {
      chartWidth: 800,
      chartHeight: 400,
      chartOptions: {
        chart: {
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
          },
        },
        legend: {
          show: false,
        },
        colors: ['#48c2c5', '#298082'],
        dataLabels: {
          enabled: true,
          formatter: (val) => {
            return val.toString() + '%';
          },
        },
        tooltip: {
          enabled: true,
          y: {
            formatter: (val) => {
              return val.toString() + '%';
            },
          },
        },
        xaxis: {
          categories: [
            'Environmental Impact',
            'Life Cycle Costing',
            'Social Impact',
            'Criticality Risk',
          ],
        },
        yaxis: {
          max: 100,
          tickAmount: 5
        }
      },
    };
  },
  created() {
    this.chartWidth = getChartSize(400, 600, 800);
    this.chartHeight = getChartSize(250, 300, 350);
  },
};
</script>

<style lang="scss">
.compare-chart {
  h2 {
    margin-bottom: 1rem;
  }
  .col-12 {
    display: flex;
    justify-content: center;
  }
}
</style>
