<template>
  <div class="row compare-servers">
    <h2 class="title-w3">Build Data Centers to Compare:</h2>
    <div class="col-12 mt-1">
        <p>The comparison is based on functional equivalence; a one-by-one comparison of servers may be wrong.</p>
      </div>
    <div class="col-12 col-md-6">
      <rack-item title="Data center 1" @saveRack="saveRack1" />
    </div>
    <div class="col-12 col-md-6">
      <rack-item title="Data center 2" @saveRack="saveRack2" />
    </div>
    <div class="col-12 text-center mt-3">
      <p class="">
        Both data centers have to be configured in order to see comparison
        results.
      </p>
    </div>
    <div class="col-12 btn-center compare-buttons">
      <button class="btn" :disabled="!rack1 || !rack2" @click="compare">
        Compare
      </button>
      <button class="btn btn-outlined" :disabled="!rack1 || !rack2" @click="exportResults">Export Results</button>
    </div>
  </div>
  <compare-chart :chartData="chartData" v-if="chartData.length" />
</template>

<script>
import Scores from '@/utils/services/Scores';
import CompareChart from '@/components/compare/CompareChart';
import RackItem from '@/components/configure/Rack';
import { ElMessage, ElLoading } from 'element-plus';

export default {
  name: 'CompareRow',
  components: {
    CompareChart,
    RackItem,
  },
  emits: ['showResults'],
  data() {
    return {
      rack1: false,
      rack2: false,
      showResults: false,
      chartData: [
        {
          name: 'Data center 1',
          data: [0, 0, 0, 0],
        },
        {
          name: 'Data center 2',
          data: [0, 0, 0, 0],
        },
      ],
      rackData1: [],
      rackData2: [],
    };
  },
  methods: {
    saveRack1(data) {
      this.rack1 = true;
      this.rackData1 = data;
    },
    saveRack2(data) {
      this.rack2 = true;
      this.rackData2 = data;
    },
    compare() {
      const chartData = Scores.getCompareScores(this.rackData1, this.rackData2);
      this.chartData = [];
      this.$nextTick(() => {
        this.chartData = chartData;
      });
    },
    async exportResults() {
      let dc1 = [];
      let dc2 = [];
      
      const loading = ElLoading.service({
        lock: true,
        background: 'rgba(0, 0, 0, 0.6)',
      });

      for (let i in this.rackData1) {
        dc1.push({
          server: this.rackData1[i].server,
          ...this.rackData1[i].config,
        });
      }

      for (let i in this.rackData2) {
        dc2.push({
          server: this.rackData2[i].server,
          ...this.rackData2[i].config,
        });
      }

      const response = await this.$store.dispatch('exportResults', {
        dc1,
        dc2,
      });

      if (response === 200) {
        ElMessage({
          message:
            'An email containing details assessment results will be sent to your email address shortly.',
          type: 'success',
          offset: 150,
          duration: 5000
        });
      } else {
        ElMessage({
          message: 'Something went wrong. Please try again.',
          type: 'error',
          offset: 150,
          duration: 5000
        });
      }

      loading.close();
    },
  },
};
</script>

<style lang="scss">
.compare-servers {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  .compare-buttons {
    .btn:nth-child(1) {
      margin-right: 0.5rem;
    }
    .btn:nth-child(2) {
      margin-left: 0.5rem;
    }
  }
}
</style>
