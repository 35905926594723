const hours = [
  { label: '1h', value: 1 },
  { label: '2h', value: 2 },
  { label: '3h', value: 3 },
  { label: '4h', value: 4 },
  { label: '5h', value: 5 },
  { label: '6h', value: 6 },
  { label: '7h', value: 7 },
  { label: '8h', value: 8 },
  { label: '9h', value: 9 },
  { label: '10h', value: 10 },
  { label: '11h', value: 11 },
  { label: '12h', value: 12 },
  { label: '13h', value: 13 },
  { label: '14h', value: 14 },
  { label: '15h', value: 15 },
  { label: '16h', value: 16 },
  { label: '17h', value: 17 },
  { label: '18h', value: 18 },
  { label: '19h', value: 19 },
  { label: '20h', value: 20 },
  { label: '21h', value: 21 },
  { label: '22h', value: 22 },
  { label: '23h', value: 23 },
  { label: '24h', value: 24 },
];

export default hours;
